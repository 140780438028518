import LayoutSideNavbar, {
  Container,
} from 'components/layouts/LayoutSideNavbar'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import { FORM_ERROR } from 'final-form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { usePlansV2 } from 'resources/PlansV2'
import Form, { FormValues } from './Form'
import Overview, { formValuesToData } from './Overview'

function NewPlanV2() {
  const { t } = useTranslation()

  const [formValues, setFormValues] = useState<Partial<FormValues>>()

  const history = useHistory()
  const { resource } = usePlansV2()

  const handleSubmit = async (values: FormValues) => {
    const {
      name,
      price,
      description,
      imageUrl,
      treatments,
      perks,
      excludedGoodsServices,
      internalReference,
      internalDescription,
      allowNewMembers,
    } = values

    try {
      await resource?.create({
        name,
        price,
        description,
        imageUrl: imageUrl.includes('no-image') ? undefined : imageUrl,
        treatments: treatments?.map(treatment => ({
          legacyId: Number(treatment.merchantTreatment.treatment?.id),
          name:
            treatment.otherName || treatment.merchantTreatment.treatment?.name,
          category: treatment.otherCategory,
          quantity: treatment.quantity,
        })),
        perks: perks?.map(perk => ({
          name: perk.name,
          quantity: undefined,
        })),
        excludedGoodsServices,
        internalReference,
        internalDescription,
        _private: !allowNewMembers,
      })

      history.push('/plans')
    } catch (error) {
      // Handle duplicate plans
      if (
        error instanceof Error &&
        error.message.includes('entity already exists')
      ) {
        return {
          name: t('This name is already being used'),
          internalReference: t('This description is already being used'),
        }
      }
      return {
        [FORM_ERROR]: t('Failed to create plan'),
      }
    }
  }

  return (
    <LayoutSideNavbar>
      <Container>
        <TwoColumns>
          <Column>
            <Form
              onFormChange={setFormValues}
              onSubmit={handleSubmit}
              submitButtonLabel={t('Create plan')}
            />
          </Column>
          <Column position="sticky" top={0}>
            <Overview data={formValuesToData(formValues)} />
          </Column>
        </TwoColumns>
      </Container>
    </LayoutSideNavbar>
  )
}

export default NewPlanV2
