import { Modal, View } from '@tabeo/scarf'
import React from 'react'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import BulkTransfer from './BulkTransfer'
import Default from './Default'
import Signup from './Signup'

export type CancelModalProps = React.ComponentProps<typeof Modal>

const variants = {
  signup: Signup,
  bulk_transfer: BulkTransfer,
  default: Default,
}

type Variant = keyof typeof variants

const statusToVariant: Record<string, Variant> = {
  sent: 'signup',
  sign_up: 'signup',
  bulk_transfer: 'bulk_transfer',
}

const CancelModal = React.forwardRef(
  ({ ...rest }: CancelModalProps, ref: any) => {
    const { data } = useSubscriptionV2()

    const status = data?.subscription?.status || ''
    const variant = statusToVariant[status] || 'default'

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          return isOpen ? (
            <View
              maxWidth={['90%', '620px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              {React.createElement(variants[variant], { onClose: close })}
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default CancelModal
