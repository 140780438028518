import { plansorchestrator } from '@tabeo/platform-api-client'
import { StateFunnel } from '@tabeo/scarf'

type ProgressbarProps = {
  data?: plansorchestrator.ModelsGetSubscriptionsSubscription
}

function Progressbar({ data }: ProgressbarProps) {
  const status = data?.status

  if (!status || !['sent', 'sign_up'].includes(status)) {
    return null
  }

  const currentIndex = status === 'sent' ? 0 : 1

  return (
    <StateFunnel
      states={['Sent', 'Sign up', 'Active']}
      pastStepProps={{ bg: 'yellow.5', color: 'yellow.2' }}
      activeStepProps={{ bg: 'yellow.4', color: 'yellow.1' }}
      futureStepProps={{ bg: 'white', color: 'ink.2' }}
      currentIndex={currentIndex}
    />
  )
}

export default Progressbar
