import { Caption, Container, Flex, View } from '@tabeo/scarf'
import { Anchor as DefaultAnchor } from 'components/Link'
import Logo from 'components/Logo'
import config from 'config'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const { LANDING_PAGE_URL } = config
const Anchor = styled(DefaultAnchor)``

Anchor.defaultProps = {
  color: 'ink.0',
  fontWeight: 'regular',
}

const Footer = () => {
  const { t } = useTranslation()
  return (
    <Container
      bg="white"
      wrapProps={{
        px: ['44px', '60px'],
        py: [8],
      }}
    >
      <Flex alignItems="flex-start" flexWrap={['wrap', 'nowrap']}>
        <View width={[1, 0.4]} mb={[8, 0]}>
          <Logo maxHeight="30px" width="101px" />
        </View>
        <View width={[1, 0.25]} mb={[6, 0]}>
          <Caption mb={3} color="primary.3">
            {t('Company')}
          </Caption>
          <Anchor
            display="block"
            mb={3}
            href={`${LANDING_PAGE_URL}/about`}
            target="_blank"
          >
            {t('About')}
          </Anchor>
          <Anchor
            display="block"
            mb={3}
            href={`${LANDING_PAGE_URL}/terms-and-conditions`}
            target="_blank"
          >
            {t('Terms & conditions')}
          </Anchor>
          <Anchor
            display="block"
            mb={3}
            href={`${LANDING_PAGE_URL}/privacy-policy`}
            target="_blank"
          >
            {t('Privacy policy')}
          </Anchor>
          <Anchor
            display="block"
            href={`${LANDING_PAGE_URL}/cookies`}
            target="_blank"
          >
            {t('Cookie policy')}
          </Anchor>
        </View>
        <View width={[1, 0.2]} mb={[6, 0]}>
          <Caption mb={3} color="primary.3">
            {t('Product')}
          </Caption>
          <Anchor
            display="block"
            mb={3}
            href={`${LANDING_PAGE_URL}/finance`}
            target="_blank"
          >
            {t('Finance')}
          </Anchor>
          <Anchor
            display="block"
            href={`${LANDING_PAGE_URL}/plans`}
            target="_blank"
          >
            {t('Plans')}
          </Anchor>
        </View>
        <View width={[1, 0.15]}>
          <Caption mb={3} color="primary.3">
            {t('Help')}
          </Caption>
          <Anchor
            display="block"
            mb={3}
            href="https://intercom.help/tabeo"
            target="_blank"
          >
            {t('FAQ')}
          </Anchor>
          <Anchor display="block" as={Link} to="/support">
            {t('Contact Us')}
          </Anchor>
        </View>
      </Flex>
      <View pt={8}>
        <Caption color="ink.2" fontSize="10px">
          <Trans>
            Tabeo LTD, incorporated in England & Wales (registration number
            10363602), with its registered office at C/O Fox Williams, 10
            Finsbury Square, Finsbury, London EC2A 1AF; and Tabeo Broker
            Limited, incorporated in England & Wales (registration number
            10416530), with its registered office at C/O Fox Williams, 10
            Finsbury Square, Finsbury, London EC2A 1AF. Tabeo Finance Limited is
            authorised and regulated by the Financial Conduct Authority and
            entered on the Financial Services Register, reference number 777539.
            <br />
            <br />
            ©Tabeo Tech Limited, all rights reserved. By visiting our website
            you agree to our Cookies policy.
          </Trans>
        </Caption>
      </View>
    </Container>
  )
}

export default Footer
