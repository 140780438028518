import { usePromise } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'

type SignupProps = {
  onClose: () => void
}

export default function Signup({ onClose }: SignupProps) {
  const { t } = useTranslation()
  const { resource } = useSubscriptionV2()

  const [trigger, { pending }] = usePromise(async () => {
    if (resource?.cancel) {
      await resource.cancel()
    }
  })

  return (
    <div className="space-y-5">
      <h3 className="title">
        {t('Are you sure you want to cancel this invitation?')}
      </h3>
      <div>
        {t(
          'Canceling the invitation will revoke access for this customer, and they will not be able to activate their membership on this plan. This action cannot be undone.'
        )}
      </div>
      <div className="space-y-4">
        <Button
          variant="primary"
          className="w-full"
          loading={pending}
          onClick={trigger}
        >
          {t('Confirm')}
        </Button>
        <Button variant="secondary" className="w-full" onClick={onClose}>
          {t('Close')}
        </Button>
      </div>
    </div>
  )
}
