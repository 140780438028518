import { Modal } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { usePlanV2 } from 'resources/PlanV2'
import DeleteModal from './DeleteModal'

export default function DeleteButton() {
  const { t } = useTranslation()
  const modal = useRef<Modal>()
  const { data } = usePlanV2()

  if (!data?.isDeletable) {
    return null
  }

  return (
    <>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => {
          modal.current?.open()
        }}
      >
        {t('Delete plan')}
      </Button>
      <DeleteModal ref={modal} />
    </>
  )
}
