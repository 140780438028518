import { plansorchestrator } from '@tabeo/platform-api-client'
import { Icons, Panel, Switch } from '@tabeo/scarf'
import { Tooltip } from '@tabeo/scarf2'
import { useTranslation } from 'react-i18next'

type SharingProps = {
  data?: plansorchestrator.ModelsPlan
}

function Sharing({ data }: SharingProps) {
  const { t } = useTranslation()

  return (
    <Panel
      header={
        <div className="flex">
          <Panel.Title>{t('Sharing')}</Panel.Title>
          <Tooltip
            title={t(
              'Turning this off will prevent new members from signing up. Existing members will not be affected.'
            )}
          >
            <Icons.Questionmark />
          </Tooltip>
        </div>
      }
    >
      <div className="flex items-center justify-between px-5 py-4">
        <label className="text-tabeo-ink-0" htmlFor="privateSwitch">
          {t('Allow new members')}
        </label>
        <Tooltip
          title={t(
            'New members are not currently allowed. Stay tuned for this to be added'
          )}
        >
          <Switch
            // eslint-disable-next-line no-underscore-dangle
            key={data?._private ? 'private' : 'public'}
            id="privateSwitch"
            // eslint-disable-next-line no-underscore-dangle
            defaultValue={!data?._private}
            input={{}}
            disabled
          />
        </Tooltip>
      </div>
    </Panel>
  )
}

export default Sharing
