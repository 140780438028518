import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as XGray } from 'components/checkout-payment/assets/x-gray.svg'
import { useTranslation } from 'react-i18next'

export default function Expired() {
  const { t } = useTranslation()

  return (
    <ActionCard>
      <ActionCard.Header>
        <XGray className="h-6 w-6" />
        {t('Membership expired')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'This membership is now expired. There are no further actions required. You can always invite the member to this plan again or to a different one if they wish to rejoin.'
        )}
      </ActionCard.Body>
    </ActionCard>
  )
}
