import { Modal, View, usePromise } from '@tabeo/scarf'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePlanV2 } from 'resources/PlanV2'
import { Button } from '@tabeo/scarf2'
import { useHistory } from 'react-router-dom'

export type DeleteModalProps = React.ComponentProps<typeof Modal>

const DeleteModal = React.forwardRef(
  ({ ...rest }: DeleteModalProps, ref: any) => {
    const { t } = useTranslation()
    const { resource } = usePlanV2()
    const history = useHistory()

    const [trigger, { pending }] = usePromise(async () => {
      if (resource?.deletePlan) {
        await resource.deletePlan()
        history.replace('/plans')
      }
    })

    return (
      <Modal ref={ref}>
        {({ isOpen, close }) => {
          return isOpen ? (
            <View
              maxWidth={['90%', '460px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              <div className="space-y-5">
                <h3 className="title">{t('Delete this plan?')}</h3>
                <div>
                  {t(
                    'You won’t be able to recover this plan once you’ve deleted it, or add any members to it. Would you like to continue?'
                  )}
                </div>
                <div className="flex gap-4">
                  <Button variant="primary" className="w-full" onClick={close}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-full"
                    loading={pending}
                    onClick={trigger}
                  >
                    {t('Delete')}
                  </Button>
                </div>
              </div>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default DeleteModal
