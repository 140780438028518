import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { Modal, View } from '@tabeo/scarf'
import { Button, Notification } from '@tabeo/scarf2'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

export type EditConfirmationModalProps = React.ComponentProps<typeof Modal> & {
  hasMembers: boolean
}

const EditConfirmationModal = React.forwardRef(
  ({ hasMembers, ...rest }: EditConfirmationModalProps, ref: any) => {
    const { t } = useTranslation()

    return (
      <Modal ref={ref}>
        {({ isOpen, close, data }) => {
          return isOpen ? (
            <View
              maxWidth={['90%', '620px']}
              mx="auto"
              bg="white"
              borderRadius={1}
              boxShadow={1}
              p={[5, 10]}
              {...rest}
            >
              <div className="space-y-5">
                <h3 className="title">
                  {hasMembers
                    ? t('Are you sure you want to edit this plan?')
                    : t(
                        'Are you sure you want to proceed with editing this plan?'
                      )}
                </h3>
                {hasMembers ? (
                  <div className="space-y-4">
                    <Notification variant="warning">
                      <ExclamationTriangleIcon />
                      {t(
                        'Editing this plan will impact its existing members, and changes will apply immediately. '
                      )}
                    </Notification>
                    <p>
                      {t(
                        'If you’re materially changing what the benefits included in this membership, please note you should communicate this to your members, and provide them with 30 days notice of the change.'
                      )}
                    </p>
                  </div>
                ) : (
                  <div className="space-y-4">
                    <p>
                      {t(
                        'This plan currently has no members, so any changes made will be applied directly without affecting any active subscriptions.'
                      )}
                    </p>
                  </div>
                )}
                <div className="flex flex-col gap-4">
                  <Button
                    variant="primary"
                    className={classNames(
                      'w-full',
                      hasMembers
                        ? 'bg-tabeo-red-3 hover:bg-tabeo-red-2 focus:bg-tabeo-red-2 active:bg-tabeo-red-1'
                        : ''
                    )}
                    onClick={() => {
                      data.onConfirm()
                      close()
                    }}
                  >
                    {t('Confirm')}
                  </Button>
                  <Button
                    variant="secondary"
                    className="w-full"
                    onClick={() => {
                      data.onReject()
                      close()
                    }}
                  >
                    {t('Close')}
                  </Button>
                </div>
              </div>
            </View>
          ) : null
        }}
      </Modal>
    )
  }
)

export default EditConfirmationModal
