import { usePromise } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as PlaneYellow } from 'components/checkout-payment/assets/plane-yellow.svg'
import { useTranslation } from 'react-i18next'
import { useSubscriptionV2 } from 'resources/SubscriptionV2'
import CancelButton from '../CancelButton'

export default function Sent() {
  const { t } = useTranslation()

  const { resource } = useSubscriptionV2()

  const [trigger, { pending, error }] = usePromise(async () => {
    if (resource?.sendReminder) {
      await resource.sendReminder()
    }
  })

  return (
    <ActionCard>
      <ActionCard.Header>
        <PlaneYellow className="h-6 w-6" />
        {t('Invitation sent')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'The invitation link has been sent to the customer. They will receive an email prompting them to sign up.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <CancelButton />
        <Button
          size="sm"
          variant="secondary"
          onClick={trigger}
          loading={pending}
        >
          {t('Send reminder')}
        </Button>
      </ActionCard.Actions>
      {error && (
        <ActionCard.Error>
          {t('There was an error, please try again later.')}
        </ActionCard.Error>
      )}
    </ActionCard>
  )
}
