import { plansorchestrator } from '@tabeo/platform-api-client'
import { Breadcrumb, Modal, Title } from '@tabeo/scarf'
import LayoutSideNavbar from 'components/layouts/LayoutSideNavbar'
import SubPage from 'components/layouts/Subpage'
import TwoColumns, { Column } from 'components/layouts/TwoColumns'
import { FORM_ERROR } from 'final-form'
import Form, { FormValues } from 'pages/NewPlanV2/Form'
import Overview, { formValuesToData } from 'pages/NewPlanV2/Overview'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { usePlanV2 } from 'resources/PlanV2'
import EditConfirmationModal from './EditConfirmationModal'

function transformDataToFormValues(data: plansorchestrator.ModelsPlan) {
  return {
    imageUrl: data.imageUrl || '/images/no-image.svg',
    name: data.name,
    description: data.description,
    price: data.price,
    treatments: data.treatments.map(t => ({
      merchantTreatment: {
        treatment: {
          id: t.legacyId,
          name: t.name,
        },
      },
      category: t.category,
      quantity: t.quantity,
      id: t.id,
    })),
    perks: data.perks.map(p => ({
      name: p.name,
      id: p.id,
    })),
    excludedGoodsServices: data.excludedGoodsServices,
    internalReference: data.internalReference,
    internalDescription: data.internalDescription,
    // eslint-disable-next-line no-underscore-dangle
    allowNewMembers: !data._private,
  }
}

function PlanV2Edit() {
  const { t } = useTranslation()
  const { data, resource } = usePlanV2()
  const history = useHistory()
  const modal = useRef<Modal>()

  const [formValues, setFormValues] = useState<Partial<FormValues>>()

  const hasMembers = data?.hasActiveSubscriptions || false

  const handleSubmit = async (values: FormValues) => {
    const result = await new Promise<any>(resolve => {
      modal.current?.open({
        values,
        onReject: () => {
          resolve({})
        },
        onConfirm: async () => {
          const {
            name,
            description,
            imageUrl,
            perks,
            treatments,
            internalReference,
            internalDescription = '',
            allowNewMembers,
            excludedGoodsServices,
          } = values

          const upsertTreatments = hasMembers
            ? undefined
            : treatments?.map(treatment => ({
                legacyId: Number(treatment.merchantTreatment.treatment?.id),
                id: treatment.id,
                name:
                  treatment.otherName ||
                  treatment.merchantTreatment.treatment?.name,
                category: treatment.otherCategory,
                quantity: treatment.quantity,
              }))
          const upsertPerks = hasMembers
            ? undefined
            : perks?.map(perk => ({
                name: perk.name,
                quantity: undefined,
                id: perk.id,
              }))

          const deleteTreatments = hasMembers
            ? undefined
            : data?.treatments
                ?.filter(
                  treatment =>
                    !treatments?.flatMap(t => t.id).includes(treatment.id)
                )
                ?.flatMap(t => String(t.id)) || undefined

          const deletePerks = hasMembers
            ? undefined
            : data?.perks
                ?.filter(perk => !perks?.flatMap(p => p.id).includes(perk.id))
                ?.flatMap(p => String(p.id)) || undefined

          try {
            await resource?.update({
              name,
              description,
              imageUrl: imageUrl.includes('no-image') ? '' : imageUrl,
              internalReference,
              internalDescription,
              upsertTreatments,
              upsertPerks,
              deleteTreatments,
              deletePerks,
              _private: !allowNewMembers,
              excludedGoodsServices: hasMembers
                ? undefined
                : excludedGoodsServices,
            })

            history.push(`/plans/${data?.id}`)
            resolve({})
          } catch (error) {
            // Handle duplicate plans
            if (
              error instanceof Error &&
              error.message.includes('entity already exists')
            ) {
              return resolve({
                name: t('This name is already being used'),
                internalReference: t('This description is already being used'),
              })
            }

            resolve({
              [FORM_ERROR]: t('Failed to update plan'),
            })
          }
        },
      })
    })

    return result
  }

  const memoizedInitialValues = useMemo(
    () => data && transformDataToFormValues(data),
    [data]
  )

  return (
    <LayoutSideNavbar isLoading={!data}>
      {data && (
        <>
          <SubPage
            renderBreadcrumb={props => (
              <Breadcrumb {...props} mb={4}>
                <Breadcrumb.Item as={Link} to="/plans">
                  {t('Plans')}
                </Breadcrumb.Item>
                <Breadcrumb.Item as={Link} to={`/plans/${data.id}`}>
                  {data.displayId}
                </Breadcrumb.Item>
              </Breadcrumb>
            )}
          >
            <Title mr={[0, 3]}>
              {t('Editing #{{id}}', { id: data.displayId })}
            </Title>
            <TwoColumns mt={4}>
              <Column>
                <Form
                  initialValues={memoizedInitialValues}
                  onFormChange={setFormValues}
                  hiddenSections={{
                    price: true,
                    treatments: hasMembers,
                    perks: hasMembers,
                    excludedGoodsServices: hasMembers,
                  }}
                  onSubmit={handleSubmit}
                  submitButtonLabel={t('Save changes')}
                />
              </Column>
              <Column position="sticky" top={0}>
                {formValues && <Overview data={formValuesToData(formValues)} />}
              </Column>
            </TwoColumns>
          </SubPage>
          <EditConfirmationModal ref={modal} hasMembers={hasMembers} />
        </>
      )}
    </LayoutSideNavbar>
  )
}

export default PlanV2Edit
