import { plansorchestrator } from '@tabeo/platform-api-client'
import { Accordion, Attributes, View } from '@tabeo/scarf'
import { Button } from '@tabeo/scarf2'
import { format } from '@tabeo/sharpei/utils/currency'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import DeleteButton from './DeleteButton'

type DetailsProps = {
  data: plansorchestrator.ModelsPlan & {
    numberOfActiveSubscriptions?: number
  }
}

function Details({ data }: DetailsProps) {
  const { t } = useTranslation()

  return (
    <View boxShadow={1} borderRadius={1} border={1} bg="white">
      <div className="space-y-5 p-5">
        <div className="flex gap-5">
          {data?.imageUrl && (
            <div className="aspect-square w-20">
              <img
                src={data?.imageUrl}
                className="h-full w-full object-cover"
              />
            </div>
          )}
          <div className="space-y-1">
            <h2 className="heading font-normal text-tabeo-primary-3">
              {data?.name}
              {data?.internalReference ? ` / ${data?.internalReference}` : ''}
            </h2>
            <p className="text-base text-tabeo-ink-0">
              {t('{{amount}} a month', { amount: format(data?.price) })}
            </p>
            <p className="text-base text-tabeo-ink-1">
              {t('{{numberOfActiveSubscriptions}} active members', {
                numberOfActiveSubscriptions:
                  data?.numberOfActiveSubscriptions || 0,
              })}
            </p>
          </div>
        </div>
        <div className="flex gap-2">
          <Link to={`/plans/${data?.id}/edit`}>
            <Button variant="secondary" size="sm">
              {t('Edit plan')}
            </Button>
          </Link>
          <DeleteButton />
        </div>
      </div>
      <Accordion title={t('Plan details')} borderTop={1}>
        <Attributes
          sections={[
            [
              {
                label: t('Internal reference'),
                value: data?.internalReference,
              },
              {
                label: t('Internal description'),
                value: data?.internalDescription,
              },
            ],
            [
              {
                label: t('External name'),
                value: data?.name,
              },
              { label: t('External description'), value: data?.description },
              {
                label: t('Price'),
                value: data?.price,
                formattedValue: t(`{{amount}} a month`, {
                  amount: format(data?.price),
                }),
              },
            ],
          ]}
        />
      </Accordion>
      <Accordion title={t('Included goods/services')} borderTop={1}>
        <Attributes
          sections={[
            [data?.treatments, data?.perks].flat().map(item => ({
              label: item?.name,
              value: (item?.quantity || 0) > 0 ? `x${item?.quantity}` : ' ',
            })),
          ]}
        />
      </Accordion>
      <Accordion title={t('Excluded goods/services')} borderTop={1}>
        <p className="caption whitespace-pre-wrap text-tabeo-ink-0">
          {data?.excludedGoodsServices}
        </p>
      </Accordion>
    </View>
  )
}

export default Details
