import { ActionCard } from 'components/checkout-payment/ActionPanel/components'
import { ReactComponent as XRed } from 'components/checkout-payment/assets/x-red.svg'
import { useTranslation } from 'react-i18next'
import CancelButton from '../CancelButton'

export default function Overdue() {
  const { t } = useTranslation()

  return (
    <ActionCard>
      <ActionCard.Header>
        <XRed className="h-6 w-6" />
        {t('Payment overdue')}
      </ActionCard.Header>
      <ActionCard.Body>
        {t(
          'This customer has missed a payment. This is typically because the customer didn’t have sufficient funds in their account, or had cancelled their direct debit. We have notified them via email and, if they have an active direct debit, will automatically retry the payment up to three times.'
        )}
      </ActionCard.Body>
      <ActionCard.Actions>
        <CancelButton />
      </ActionCard.Actions>
    </ActionCard>
  )
}
